$(function () {
    const darkModeSwitch = $('#checkbox');
    const savedTheme = localStorage.getItem('theme');

    if (savedTheme === 'dark') {
        darkModeSwitch.prop('checked', true);
        $('html').attr('data-bs-theme', 'dark');
    }

    darkModeSwitch.on('change', function () {
        if ($(this).prop('checked')) {
            $('html').attr('data-bs-theme', 'dark');
            localStorage.setItem('theme', 'dark');
        } else {
            $('html').attr('data-bs-theme', 'light');
            localStorage.removeItem('theme');
        }
    });

    $(document).on('click', '.dashboard-menu-trigger', function () {
        $('.menu-panel').toggleClass('open');
    })

    $('.obfuscated-email').each(function () {
        var obfuscated = $(this).html();
        $(this).html(obfuscated.replace(/&#(\d+);/g, function (match, code) {
            return String.fromCharCode(code);
        }));
    });

})

$.fn.copyToClipboard = function () {
    var textToCopy = $(this).text();

    // Create a temporary textarea element to copy text
    var tempTextarea = $('<textarea>');
    tempTextarea.val(textToCopy);

    // Append the textarea to the body
    $('body').append(tempTextarea);

    // Select the text in the textarea
    tempTextarea.select();
    tempTextarea[0].setSelectionRange(0, textToCopy.length);

    // Execute the copy command
    document.execCommand('copy');

    // Remove the temporary textarea
    tempTextarea.remove();

    pushNotification('Copied!', 'success');

};

$.fn.adjustColorContrast = function (color) {
    const luminance = chroma(color).luminance();
    return this.css('color', luminance > 0.5 ? 'black' : 'white');
};

$.fn.adjustBgContrast = function (color, hover) {
    const luminance = chroma(color).luminance();

    if (hover) {
        this.removeClass('light dark');

        return this.addClass(luminance > 0.5 ? 'dark' : 'light');
    } else {
        return this.css('background-color', luminance > 0.5 ? 'rgba(0, 0, 0, 0.05)' : 'rgba(255, 255, 255, 0.08)');
    }
};


$.fn.contrast = function (t, c) {
    let dispatch,
        luminance = chroma(c).luminance() > 0.5;

    dispatch = {
        text: (function (_this) {
            return function () {
                return _this.css('color', luminance ? 'black' : 'white');
            }
        })(this),
        background: (function (_this) {
            return function () {
                _this.removeClass('light dark');
                return _this.addClass(luminance ? 'dark' : 'light');
            }
        })(this)
    }

    dispatch[t]();
};


(function (global, $) {
    global.findName = function (hexa, callback) {
        $.getJSON('/data/color-names.json', function (data) {

            var colorData = data;

            // Use static hex code #ffffff
            var namer = colorData.find(color => color.hex === hexa);

            // Convert hex string to RGB
            var targetColor = chroma(hexa).rgb();

            // Find the color with the smallest difference
            var minDifference = Number.POSITIVE_INFINITY;

            colorData.forEach(color => {
                var currentColor = chroma(color.hex).rgb();
                var difference = chroma.deltaE(targetColor, currentColor);

                if (difference < minDifference) {
                    minDifference = difference;
                    namer = color;
                }
            });

            // Call the callback function with the result
            callback(namer.name);
        });
    };
})(window, $);

(function (global, $) {
    global.pushNotification = function (text, type) {
        var $toast = $('#app-toast');
        var $toastBody = $toast.find('.toast-message');

        // Update text and background color based on the type
        $toastBody.html(text);
        $toast.removeClass('bg-black bg-danger bg-info');

        if (type === 'success') {
            $toast.addClass('bg-black');
        } else if (type === 'error') {
            $toast.addClass('bg-danger');
        } else if (type === 'info') {
            $toast.addClass('bg-info');
        }

        var toast = new bootstrap.Toast($toast[0]);
        toast.show();
    }
})(window, $);

(function (global, $) {
    global.copyUrl = function () {

        var currentUrl = window.location.href;

        // Create a temporary input element
        var $tempInput = $('<input>');

        // Set the input value to the current URL
        $tempInput.val(currentUrl);

        // Append the input element to the body
        $('body').append($tempInput);

        // Select the text in the input element
        $tempInput.select();

        // Execute the "copy" command
        document.execCommand('copy');

        // Remove the temporary input element
        $tempInput.remove();

        pushNotification('Link copied!', 'success');
    }
})(window, $);
