import './bootstrap';
import './main';
import './spectrum';

import chroma from 'chroma-js';
import * as blinder from 'color-blind';
import * as bootstrap from 'bootstrap';

import InfiniteAjaxScroll from '@webcreate/infinite-ajax-scroll';

window.bootstrap = bootstrap;
window.chroma = chroma;
window.blinder = blinder;
window.InfiniteAjaxScroll = InfiniteAjaxScroll;